import React from 'react'

import styles from './styles.module.scss'

import Icon from './../Icon'
import Map from './map'

export default () => <section id="contact" className="hero is-dark is-fullheight">
  <div className="hero-body">
    <div className={["container", styles.container].join(' ')}>
      <div>
        <h1 className={["title", styles.name].join(' ')}>ПЛИСЕ ЕООД</h1>
        <h1 className="title">гр. София, ул. Екзарх Йосиф 50, ап. 7</h1>
        <h2 className="subtitle">Моля, заповядайте в офиса ни само след предварителна уговорка.</h2>

        <Map />
      </div>
      <br />
      <div className={styles.item}>Email: <a href="mailto:office@plise.bg">office@plise.bg</a></div>
      <div className={styles.contact}>
        <div className={styles.item}>
          <p className={styles.personalName}>Ангелина Петкова</p>
          <p>(Инженер технолог)</p>
          <p className={styles.phone}><Icon icon="phone" /><span>0888704531</span></p>
        </div>
        <div className={styles.item}>
          <p className={styles.personalName}>Надя Петкова</p>
          <p>(Координатор производство)</p>
          <p className={styles.phone}><Icon icon="phone" /><span>0888373004</span></p>
        </div>
      </div>
      <div>
        <p className={styles.item}>Посетете ни в Facebook на <a href="https://www.facebook.com/plisebg/">facebook.com/plisebg</a></p>
      </div>
    </div>
  </div>
</section>
