import React from 'react'

import 'bulma'
import styles from './App.module.scss'

import NavBar from './components/NavBar'
import Title from './components/Title'
import Products from './components/Products'
import Gallery from './components/Gallery'
import Customers from './components/Customers'
import FAQ from './components/FAQ'
import Contact from './components/Contact'
import Partners from './components/Partners'

export default () => <div className={styles.app}>
  <NavBar/>
  
  <Title/>
  <Products/>
  <Gallery/>
  <Customers/>
  <FAQ/>
  <Contact/>
  <Partners/>
  
  <footer className={["footer", styles.footer].join(' ')}>
    <div className="content has-text-centered">
      <p>
        <strong>ПЛИСЕ ЕООД 2023</strong>
      </p>
      <p>Всички права запазени.</p>
      <p>Web design by Alexander Dzhoganov</p>
    </div>
  </footer>
</div>
