import React, {Component} from 'react'

import styles from './styles.module.scss'

export default class NavBar extends Component {
  constructor(props) {
    super(props)

    this.state = {
      expanded: false
    }
  }

  render() {
    const {expanded} = this.state

    return <nav className={["navbar", styles.navbar].join(' ')} role="navigation" aria-label="main navigation">
      <div className={["container", styles.container].join(' ')}>
        <div className="navbar-brand">
          <a className="navbar-item" href="#title">
            <strong>plise.bg</strong>
          </a>

          <div role="button" className={["navbar-burger", "burger", expanded ? 'is-active' : ''].join(' ')} aria-label="menu"
            aria-expanded="false" data-target="navbar" onClick={() => this.setState({expanded: !expanded})}>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </div>
        </div>
      
        <div id="navbar" className={["navbar-menu", this.state.expanded ? 'is-active' : ''].join(' ')}>
          <div className="navbar-start">
            <a className="navbar-item" href="#products">Услуги</a>
            <a className="navbar-item" href="#customers">Поръчки</a>
            <a className="navbar-item" href="#faq">Въпроси и отговори</a>
            <a className="navbar-item" href="#contact">Контакти</a>
            <a className="navbar-item" href="#partners">Партньори</a>
          </div>
        </div>
      </div>
    </nav>
  }
}
