import React from 'react'

import styles from './styles.module.scss'

export default () => <section id="partners" className="hero is-warning is-fullheight">
  <div className="hero-body">
    <div className={["container", styles.container].join(' ')}>
      <h1 className="title">Нашите партньори</h1>

      <div className="columns">
        <div className="column">
          <ul>
            <li>Легия Раковски</li>
            <li>Валина Асеновград</li>
            <li>БТБ България</li>
            <li>Балкантекс</li>
            <li>Бдинтекс</li>
            <li>Бг Текс Нова</li>
            <li>Петров Текстил</li>
            <li>Пелентекс България</li>
            <li>Индустрия Мода Маркам</li>
            <li>Магик Русе</li>
            <li>Марбул Раковски</li>
            <li>Мода Ескалейт Къмпани</li>
            <li>МИК-БГ</li>
            <li>Нова Линия Русе</li>
          </ul>
        </div>
        <div className="column">
          <ul>
            <li>Мелини Фешън</li>
            <li>Flowers street</li>
            <li>Chopova Lowena</li>
            <li>Е. Миролио</li>
            <li>Стилна Жена</li>
            <li>Жени Стил</li>
            <li>Тина Интернешънъл</li>
            <li>Лав Мейд Лав Интернешънъл</li>
            <li>Ню Бояна Филм Студиос</li>
            <li>Акула ТМ</li>
            <li>Антоан Вилл</li>
            <li>Апарел Сървисис</li>
            <li>Прототип 23</li>
          </ul>
        </div>
        <div className="column">
          <ul>
            <li>Софийска опера и балет</li>
            <li>Държавна опера Русе</li>
            <li>Народен театър „Иван Вазов“</li>
            <li>Театър София</li>
            <li>Малък градски театър „Зад канала“</li>
            <li>Национална художествена академия</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section>
