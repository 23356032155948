import React, {Component} from 'react'
import Visibility from 'react-visibility-sensor'

import Icon from './../Icon'

import styles from './styles.module.scss'

const renderGallery = activate => <div className={styles.gallery}>
  <div onClick={() => activate(0)} onMouseEnter={() => activate(0)} className={[styles.galleryItem, styles.red].join(' ')}/>
  <div onClick={() => activate(1)} onMouseEnter={() => activate(1)} className={[styles.galleryItem, styles.yellow].join(' ')}/>
  <div onClick={() => activate(2)} onMouseEnter={() => activate(2)} className={[styles.galleryItem, styles.orange].join(' ')}/>
</div>

export default class Products extends Component {
  constructor(props) {
    super(props)

    this.state = {
      gallery: [0, 0, 0],
      selected: null
    }
  }

  setPage = page => this.setState({page})
  activeClass = page => this.state.page === page ? 'is-active' : ''

  render() {
    const {selected, gallery} = this.state
    const setGallery = (productIndex, imageIndex) => {
      gallery[productIndex] = imageIndex

      this.setState({
        gallery: gallery
      })
    }

    const setSelected = selected => {
      this.setState({ selected })
    }

    return <Visibility
      onChange={isVisible => isVisible ? null : setSelected(null)}
      partialVisibility={true}
      minTopValue={100}
      
    >
      <section id="products" className="hero is-white is-fullheight">
        <div className="hero-body">
          <div className={["container", styles.container].join(' ')}>
            {(!selected || selected === 'solei') && <div className={[styles.product, selected === 'solei' ? styles.productActive : ''].join(' ')}>
              <div className={styles.title} onClick={() => setSelected('solei')}>
                <div className={[styles.image, styles['solei' + gallery[0]], selected === 'solei' ? styles.active : ''].join(' ')}/>
                <span>Солей</span>
              </div>
              <div className={[styles.description, selected === 'solei' ? styles.descriptionActive : ''].join(' ')}>
                <div>
                  Солеят е нагъване на плата, по-широко в долната си част и стесняващо се нагоре.
                </div>
                {selected === 'solei' && <div>
                  <p>Солеят представлява парче, скроено като част от кръг, обичайно ½ или ¼, което е нагънато така, че в горната си част е по-тясно,
                    а надолу се разширява, тоест ръбовете на гънките се събират в един център.</p>
                  <p>Едрината на солея се определя от броя нагъвания в рамките на половин кръг и от това на какъв радиус е скроен.
                    Стандартно нагъванията в едно парче от половин кръг могат да бъдат 80, 100 или 120.</p>
                  <p>Солеите се срещат най-често под формата на дълги поли или рокли, които стоят много пищно.
                    Понякога се използват и за гръб на блуза, ръкави или артистични костюми.</p>
                  <p>Подходящи материи за солей са всякакви полиестери, например шифон или сатен.
                    Можем да изработваме както много дълги солеи за бални рокли, така и частичен солей върху определен участък от плата,
                    или пък например къси поли от изкуствена кожа или тюл.</p>
                  <p>Солеят не може да се преглажда и се пере препоръчително на ръка в хладка вода. Трябва да се пази от загряване.</p>
                  <p className={styles.example}>
                    <a href={process.env.PUBLIC_URL + '/solei.gif'} target="_blank" rel="noopener noreferrer"><Icon icon="animation" className={styles.animIcon}/> Пример</a>
                  </p>
                </div>}
                <div>{renderGallery(index => setGallery(0, index))}</div>
              </div>
            </div>}
            {(!selected || selected === 'plise') && <div className={[styles.product, styles.right, selected === 'plise' ? styles.productActive : ''].join(' ')}>
              <div className={[styles.description, styles.descriptionRight, selected === 'plise' ? styles.descriptionActive : ''].join(' ')}>
                <div>Плисето е нагъване на плата, което заляга на една страна в една и съща посока.</div>
                {selected === 'plise' && <div>
                  <p>Плисето има няколко разновидности.</p>
                  <p>Стандартното плисе заляга на една страна. То може да бъде пълно или плитко в зависимост от това колко от плата се подгъва навътре.
                    Има възможност и да се направи с леко разширяване надолу.</p>
                  <p>От стандартно плисе стават чудесни карирани поли или поли за официални случаи, униформи, декорации за ръкави.</p>
                  <p>Контраплисето, познато още като блокплисе, е нагъване, при което плисетата застават едно срещу друго през едно.
                    Стои много елегантно на ръкави или гръб на блуза или шлифер.</p>
                  <p>Изработката от полиестерни материи позволява плисето да не се разваля,
                    но естеството на нагъване (да заляга на една страна) позволява плисето да бъде преглаждано.
                    Затова е възможно да се плисират и други материи, например студена вълна.</p>
                  <p>Освен стандартни детайли за дълги поли плисе, ние можем да изработим и малки детайли частично 
                    плисе върху определен участък от дреха.</p>
                  <p className={styles.example}>
                    <a href={process.env.PUBLIC_URL + '/plise.gif'} target="_blank" rel="noopener noreferrer"><Icon icon="animation" className={styles.animIcon}/> Пример</a>
                  </p>
                </div>}
                <div>{renderGallery(index => setGallery(1, index))}</div>
              </div>
              <div className={[styles.title].join(' ')} onClick={() => setSelected('plise')}>
                <div className={[styles.image, styles['plise' + gallery[1]], selected === 'plise' ? styles.active : ''].join(' ')}/>
                <span>Плисе</span>
              </div>
            </div>}
            {(!selected || selected === 'frize') && <div className={[styles.product, selected === 'frize' ? styles.productActive : ''].join(' ')}>
              <div className={styles.title} onClick={() => setSelected('frize')}>
                <div className={[styles.image, styles['frize' + gallery[2]], selected === 'frize' ? styles.active : ''].join(' ')}/>
                <span>Фризе</span>
              </div>
              <div className={[styles.description, selected === 'frize' ? styles.descriptionActive : ''].join(' ')}>
                <div>Фризето e нагъване на плата като хармоника с еднакъв размер в горната и долната част.</div>
                {selected === 'frize' && <div>
                  <p>То не заляга на една страна като плисето, а стои изпъкнало.</p>
                  <p>Най-често фризето се използва в направата на народни носии и изобщо за обработка на по-дебели материи.
                    Също така от тюл под формата на фризе се изработват пачки за балерини или модели на поли и рокли, при които се търси обем.
                    Използва се и за декорация като малки детайли за ръкави, биета и други.</p>
                  <p>Ние можем да изработваме фризе както за дълги поли, така и на определен участък от голям детайл.</p>
                  <p className={styles.example}>
                    <a href={process.env.PUBLIC_URL + '/frize.gif'} target="_blank" rel="noopener noreferrer"><Icon icon="animation" className={styles.animIcon}/> Пример</a>
                  </p>
                </div>}
                <div>{renderGallery(index => setGallery(2, index))}</div>
              </div>
            </div>}

            {selected && <a href="#products" className={styles.back} onClick={() => setSelected(null)}>&lt; Върни се назад</a>}
          </div>
        </div>
      </section>
    </Visibility>
  }
}
