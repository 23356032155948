import React from 'react'

import { useJsApiLoader, GoogleMap, Marker } from "@react-google-maps/api"

const containerStyle = {
  width: '100%',
  height: '400px'
};

const center = {
  lat: 42.7007198,
  lng: 23.3294958
};

export default function Map() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyDNnswBXwk2R5u2zlLVN_TQ8S-thPwtlOs"
  })

  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={16}
    >
      <Marker
        position={{ lat: 42.700753, lng: 23.329936 }}
        onClick={() => window.location.href = "https://www.google.com/maps/place/42%C2%B042'02.7%22N+23%C2%B019'47.8%22E/@42.700741,23.3293928,19z/data=!3m1!4b1!4m6!3m5!1s0x0:0x0!7e2!8m2!3d42.7007408!4d23.3299405?hl=bg"}
      />
    </GoogleMap>
  ) : <></>
}