import React from 'react'

import styles from './styles.module.scss'

export default () => <section id="faq" className="hero is-info">
  <div className="hero-body">
    <div className={["container", styles.container].join(' ')}>
      <h1 className={["title", styles.title].join(' ')}>Въпроси и отговори</h1>

      <div className={["box", styles.faq].join(' ')}>
        <div className={styles.faqTitle}>
          Какво изработвате?
        </div>
        <div className={styles.faqAnswer}>
          <p>Големи и малки детайли тип солей, фризе, плисе, и фино намачкване за рокли, поли, блузи, панталони, ръкави,
            народни носии, артистични костюми и крила солей, лампи, шапки, малки пердета.</p>
        </div>
      </div>

      <div className={["box", styles.faq].join(' ')}>
        <div className={styles.faqTitle}>
          Предлагате ли готови платове на метър или топове?
        </div>
        <div className={styles.faqAnswer}>
          <p>Работим само с плат на клиента и предварително скроени детайли. Не предлагаме топове и готови детайли, нито ушиване.</p>
        </div>
      </div>

      <div className={["box", styles.faq].join(' ')}>
        <div className={styles.faqTitle}>
          Как да Ви обясня какво искам?
        </div>
        <div className={styles.faqAnswer}>
          <p>Изпратете ни като прикачен файл на имейл или на вайбър снимка на точния или сходен модел.
            Можете да намерите и описание на различните видове нагъване на плата по-горе.</p>
        </div>
      </div>

      <div className={["box", styles.faq].join(' ')}>
        <div className={styles.faqTitle}>
          Какъв плат е подходящ?
        </div>
        <div className={styles.faqAnswer}>
          <p>За солей и фризе платът задължително трябва да съдържа поне 70% полиестер, например полиестерна коприна, шифон, жоржет, органза, тюл. Възможно е да бъде и кожа - естествена и изкуствена. <strong>Естествената коприна и вискозата не са подходящи.</strong></p>
          <p>За плисе изборът е по-голям, тъй като плисето позволява преглаждане. Вълната също е подходящ материал, но се поддържа само с химическо чистене.</p>
        </div>
      </div>

      <div className={["box", styles.faq].join(' ')}>
        <div className={styles.faqTitle}>
          Колко плат е необходим за пола солей?
        </div>
        <div className={styles.faqAnswer}>
          <p>Обичайно между 3 и 6 метра за една пола в зависимост от дължината ѝ и от това колко части от кръг искате да сложите.</p>
        </div>
      </div>

      <div className={["box", styles.faq].join(' ')}>
        <div className={styles.faqTitle}>
          Колко плат е необходим за пола плисе?
        </div>
        <div className={styles.faqAnswer}>
          <p>За ханш под 100см за необходими 2 дължини плат по 1.50м, скроени и подгънати на съответната дължина, която желаете.</p>
        </div>
      </div>

      <div className={["box", styles.faq].join(' ')}>
        <div className={styles.faqTitle}>
          Трябва ли платът да бъде предварително скроен и подгънат?
        </div>
        <div className={styles.faqAnswer}>
          <p>За плисе и фризе парчетата трябва да бъдат скроени и подгънати предварително, но да не бъдат съединявани.
            Солеят също се крои предварително, като подгъв се прави предварително само за платове, които са по-стегнати.
            За другите изравняването се прави след ушиване на дрехата, за да се отстрани провисването на верева.</p>
        </div>
      </div>

      <div className={["box", styles.faq].join(' ')}>
        <div className={styles.faqTitle}>
          В коя посока се крои плисето?
        </div>
        <div className={styles.faqAnswer}>
          <p>Линиите на плисетата трябва да бъдат успоредни на ивата на плата.
            Това означава, че максималната ширина на едно парче може да бъде 150см, колкото са стандартните топове плат.</p>
        </div>
      </div>

      <div className={["box", styles.faq].join(' ')}>
        <div className={styles.faqTitle}>
          За колко време изпълнявате поръчките?
        </div>
        <div className={styles.faqAnswer}>
          <p>Индивидуални поръчки изпълняваме в рамките на 2-3 работни дни. В някои случаи е възможна изработка и в деня на получаване.</p>
        </div>
      </div>

      <div className={["box", styles.faq].join(' ')}>
        <div className={styles.faqTitle}>
          Как да проверя дали платът ще се повреди при пране?
        </div>
        <div className={styles.faqAnswer}>
          <p>С обикновена ютия заглаждате една гънка, след което я намокряте.
            Ако гънката се изправи или намачка, платът не е подходящ.
            Друг лесен начин да проверите е като смачкате в дланта си парче от плата.
            Ако след мачкането са останали следи, това значи, че платът не е подходящ и ще се повреди дори при сядане.</p>
        </div>
      </div>

      <div className={["box", styles.faq].join(' ')}>
        <div className={styles.faqTitle}>
          Как се пере?
        </div>
        <div className={styles.faqAnswer}>
          <p>На ръка, в хладка вода, без изцеждане. </p>
        </div>
      </div>

      <div className={["box", styles.faq].join(' ')}>
        <div className={styles.faqTitle}>
          Могат ли да се гладят дрехите с плисе и солей?
        </div>
        <div className={styles.faqAnswer}>
          <p><strong>Солеят не се глади.</strong> Трябва да се пази и от топло - да не се опушва,
          оставя до радиатор, върху гладачна маса и други. Плисето, ако не е силно повредено, може да бъде преглаждано.</p>
        </div>
      </div>

      <div className={["box", styles.faq].join(' ')}>
        <div className={styles.faqTitle}>
          Могат ли повредени дрехи със солей и плисе да се поправят?
        </div>
        <div className={styles.faqAnswer}>
          <p>Поправката на дрехи се извършва след като повреденият детайл се разшие от останалата част от дрехата.
            Плисира се или се солира наново и повторно се пришива.
            Понякога поправката може да се извърши директно върху ушитата дреха, като за целта трябва да покажете снимка
            на повреденото изделие.  В повечето случаи проблемът идва от избора на неподходяща материя от производителя
            (дори и за скъпи артикули) и корекцията трае само до следващото пране.</p>
        </div>
      </div>

      <div className={["box", styles.faq].join(' ')}>
        <div className={styles.faqTitle}>
          Предлагате ли скрояване и ушиване?
        </div>
        <div className={styles.faqAnswer}>
          <p>За целта ще трябва да се обърнете към шивач или за големи поръчки – към фабрика.
            Извършваме услугата скрояване само за единични бройки, когато клиентът няма тази възможност.</p>
        </div>
      </div>

      <div className={["box", styles.faq].join(' ')}>
        <div className={styles.faqTitle}>
          Как да Ви изпратим плата?
        </div>
        <div className={styles.faqAnswer}>
          <p>Ако сте от София, можете да го донесете направо в нашия офис.
            За клиенти от други населени места работим с всички куриерски фирми. </p>
        </div>
      </div>
    </div>
  </div>
</section>
