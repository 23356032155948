import React from 'react'
import Carousel from 'nuka-carousel'
import { easeQuad } from 'd3-ease'

import styles from './styles.module.scss'

const galleryUrl = index => `${process.env.PUBLIC_URL}/gallery/${index}.jpg`
const images = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map(galleryUrl)

export default () => <section id="gallery">
  <Carousel
  className={styles.container}
  width="100%"
  height="400px"
  slideWidth="1000px"
  autoplay={true}
  autoplayInterval={3000}
  pauseOnHover={true}
  wrapAround={true}
  easing={easeQuad}
  renderCenterLeftControls={({ previousSlide }) => (
    <button className={styles.button} onClick={previousSlide}><span>&lt;</span></button>
  )}
  renderCenterRightControls={({ nextSlide }) => (
    <button className={styles.button} onClick={nextSlide}><span>&gt;</span></button>
  )}
  renderBottomCenterControls={() => null}
  >
    {images.map(image => <img key={image} src={image} alt=""/>)}
  </Carousel>
</section>
