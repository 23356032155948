import React from 'react'

import styles from './styles.module.scss'

export default () => <section id="customers" className="hero is-dark is-fullheight">
  <div className={["hero-body", styles.container].join(' ')}>
    <div className="container">
      <div className="columns">
        <div className={["column", "box", styles.business].join(' ')}>
          <h1 className={["title", styles.title].join(' ')}>За бизнес клиенти</h1>
          <div className={styles.info}>
            <p>Благодарение на собствено разработената ни технология имаме възможност да изпълняваме големи поръчки за нашите клиенти на едро.</p>
            <p>Капацитетът  ни за солей и фризе е около 1000-1500 изделия на месец в зависимост от размера и сложността на изделието.
            Производителността ни за плисе не е голяма и затова приемаме предимно малки поръчки.</p>
            <p>Ако искате да запазите дати за производство при нас, моля свържете се с нас максимално рано - по възможност поне месец предварително.</p>
            <p>Можем да разработваме матрици за изделия по конкретен модел при подаване на готова мостра и чертеж от производителя,
              като разработката им се заплаща допълнително.</p>
            <p>Цените за производство се формират според изделието и обема на поръчката, чак след изработката на мострени бройки.</p>
            <p>За големи поръчки особено внимание при изработката на солей трябва да се обърне на разположението на правата нишка при кроене и на
            това подгъвите <a className={styles.example} href={process.env.PUBLIC_URL + '/example.jpg'} target="_blank" rel="noopener noreferrer">да не бъдат разтегнати</a>. Трябва да се вземе предвид и провисването по верев,
            защото изравняването на дрехата след солиране е изключително трудно. Препоръчваме и предварителното тестване на няколко материи,
            тъй като те са от решаващо значение за издръжливостта на изделието.</p>
          </div>
        </div>
        <div className={["column", styles.individual].join(' ')}>
          <h1 className={["title", styles.title].join(' ')}>Индивидуални поръчки</h1>
          <div className={styles.info}>
            <p>Ако искате да си ушиете дреха по ваш вкус или за специално събитие, ние можем да изработим различни видове солей,
              плисе и фризе или да крешираме плата. За скрояване и ушиване ще трябва да се обърнете към шивач.</p>
            <p>Най-добре е първо да ни изпратите снимка или рисунка на желания модел, ако разполагате с такава.
              Можем да Ви консултираме по телефона или на място в нашия офис относно дали е възможно и как да изпълните модела,
              както и колко и какъв плат е необходим.</p>
            <p>Подходящите материи за солей и плисе са полиестерни, естествените материи се развалят.
              Можете да проверите това като направите сгъвка с ютия и намокрите мястото.
              Ако пък сте в магазина и се чудите дали да купите някоя материя, смачкайте я в длан и проверете дали остават следи.
              Подходящите материи няма да се смачкат или повредят от мокрене и притискане.</p>
            <p>Можете да донесете скроения плат в нашия офис или да го изпратите по куриер, ако не сте от София.
              Моля, позвънете по телефона предварително, за да уточним ден и час.</p>
            <p>Единични бройки понякога е възможно да бъдат изпълнени експресно в рамките на същия работен ден,
              но обичайно отнемат до 2-3 работни дни.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
