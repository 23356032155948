import React from 'react'

import styles from './styles.module.scss'

export default () => <section id="title" className="hero is-fullheight">
  <div className={["hero-body", styles.heroBody].join(' ')}>
    <div className={["container", styles.container].join(' ')}>
      <video className={styles.video} muted={true} autoPlay={true} playsInline={true} loop={true} poster={process.env.PUBLIC_URL + '/title_bg.jpeg'}>
        <source src={process.env.PUBLIC_URL + '/title_bg.webm'} type='video/webm; codecs="vp8, vorbis"'/>
        <source src={process.env.PUBLIC_URL + '/title_bg.mp4'} type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'/>
        <source src={process.env.PUBLIC_URL + '/title_bg.ogv'} type='video/ogg; codecs="theora, vorbis"'/>
        <img src={process.env.PUBLIC_URL + '/title_bg.jpeg'} alt=""/>
      </video>
      <div className={styles.text}>
        <h1 className={["title", styles.company].join(' ')}>
          ПЛИСЕ ЕООД
        </h1>
        <h1 className={["title", styles.title].join(' ')}>
          Изработката на плисе и солей за нас е традиция от 40 години.
        </h1>
        <h2 className={["subtitle", styles.subtitle].join(' ')}>
          Разработената от нас оригинална технология позволява да задоволим разнообразни и специфични изисквания. 
        </h2>
      </div>
    </div>
  </div>
</section>
